define("shared/components/resource-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "erKXSwUR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n  \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"clusterNew.agentConfig.resourceRequirements.cpu\"],[[\"type\"],[[35,4]]]]],[2,\"\\n  \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\"],[6,[37,6],null,[[\"editable\",\"value\"],[[35,5],[35,3]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"step\",\"value\",\"classNames\",\"placeholder\"],[\"100\",[35,3],\"form-control\",[30,[36,0],[\"formSecurity.milliCpuReservation.placeholder\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[10,\"div\"],[14,0,\"input-group-addon bg-default\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"generic.mCPU\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[14,\"for\",\"input-milli-limit-memory\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"clusterNew.agentConfig.resourceRequirements.memory\"],[[\"type\"],[[35,4]]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n\"],[6,[37,6],null,[[\"editable\",\"value\"],[[35,5],[35,1]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"step\",\"value\",\"classNames\",\"placeholder\"],[\"1\",[35,1],\"form-control\",[30,[36,0],[\"formSecurity.memoryReservation.placeholder\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[10,\"div\"],[14,0,\"input-group-addon bg-default\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"generic.mibibyte\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"memory\",\"input-integer\",\"cpu\",\"type\",\"editing\",\"input-or-display\"]}",
    "moduleName": "shared/components/resource-list/template.hbs"
  });

  _exports.default = _default;
});