define("shared/components/form-pod-affinity-k8s/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t7ocVkun",
    "block": "{\"symbols\":[\"term\"],\"statements\":[[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"clusterNew.agentConfig.overrideAffinity.podAffinity.title\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-12\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[8,\"form-pod-affinity-term-k8s\",[],[[\"@value\",\"@editing\",\"@remove\",\"@typeChanged\",\"@antiChanged\"],[[32,1],[34,2],[30,[36,0],[[32,0],\"removeTerm\",[32,1]],null],[30,[36,0],[[32,0],\"typeChanged\",[32,1]],null],[30,[36,0],[[32,0],\"antiChanged\",[32,1]],null]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\"],[6,[37,6],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"btn bg-link icon-btn\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"addTerm\"],null],[12],[2,\"\\n          \"],[10,\"i\"],[14,0,\"icon icon-plus text-small\"],[12],[13],[2,\"\\n          \"],[10,\"span\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"clusterNew.agentConfig.overrideAffinity.podAffinity.addTerm\"],null]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"editing\",\"allTerms\",\"-track-array\",\"each\",\"if\"]}",
    "moduleName": "shared/components/form-pod-affinity-k8s/template.hbs"
  });

  _exports.default = _default;
});