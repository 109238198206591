define("shared/components/resource-list/component", ["exports", "shared/components/resource-list/template", "shared/utils/parse-unit", "shared/utils/util"], function (_exports, _template, _parseUnit, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    value: null,
    type: '',
    mode: 'new',
    editing: Ember.computed.equal('mode', 'new'),

    init() {
      this._super(...arguments);
    },

    // The form displays a MiB suffix but if edited by yaml this value may have been set with other units, eg '1Gi' should be converted to 1024 (MiB)
    // https://kubernetes.io/docs/concepts/configuration/manage-resources-containers/#meaning-of-memory
    memory: Ember.computed('value', {
      get() {
        let memory = Ember.get(this.value, 'memory');

        if (!!memory) {
          // parseSi returns memory in Bytes- multiply by number of Bytes in MiB
          memory = (0, _parseUnit.parseSi)(memory, 1024) / 1048576;
        }

        return memory;
      },

      set(key, val) {
        const out = { ...this.value
        };

        if (!!val) {
          out.memory = `${val}Mi`;
        } else if (out.memory) {
          delete out.memory;
        }

        this.update(out);
        return val;
      }

    }),
    // The form displays a mCPU suffix but if set in YAML this may be another unit, eg '1' (CPU) should be converted to 1000 (mCPU)
    cpu: Ember.computed('value', {
      get() {
        let cpu = Ember.get(this.value, 'cpu');
        return cpu ? (0, _util.convertToMillis)(cpu.toString()) : null;
      },

      set(key, val) {
        const out = { ...this.value
        };

        if (!!val) {
          out.cpu = `${val}m`;
        } else if (out.cpu) {
          delete out.cpu;
        }

        this.update(out);
        return val;
      }

    })
  });

  _exports.default = _default;
});