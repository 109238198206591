define("shared/components/form-node-affinity-k8s/component", ["exports", "shared/components/form-node-affinity-k8s/template", "shared/utils/util", "shared/components/form-pod-affinity-k8s/component"], function (_exports, _template, _util, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    TERM_PRIORITY: _component.TERM_PRIORITY,
    nodeAffinity: null,
    editing: false,
    allTerms: [],

    /**
    * this component renders one list for required & preferred arrays of node selector terms
    * each nodeaffinitytermk8s component can change between required and preferred
    * the overall list shouldn't re-order when a term is moved to a different underlying array so rather than computing this off the arrays in spec
    * this list will track which array a term should belong to and the arrays in spec will be computed off this
    *
    * list of all terms:
    * - {
    *    priority: preferred/required
    *    term:preferred or required term
    *   }
    *
    */
    init() {
      var _this$nodeAffinity, _this$nodeAffinity2, _this$nodeAffinity2$r;

      this._super(...arguments);

      const allTerms = [];

      const addTerms = function (terms = [], priority) {
        terms.forEach(term => {
          allTerms.push({
            priority,
            term
          });
        });
      };

      addTerms((_this$nodeAffinity = this.nodeAffinity) === null || _this$nodeAffinity === void 0 ? void 0 : _this$nodeAffinity.preferredDuringSchedulingIgnoredDuringExecution, _component.TERM_PRIORITY.PREFERRED);
      addTerms((_this$nodeAffinity2 = this.nodeAffinity) === null || _this$nodeAffinity2 === void 0 ? void 0 : (_this$nodeAffinity2$r = _this$nodeAffinity2.requiredDuringSchedulingIgnoredDuringExecution) === null || _this$nodeAffinity2$r === void 0 ? void 0 : _this$nodeAffinity2$r.nodeSelectorTerms, _component.TERM_PRIORITY.REQUIRED);
      Ember.set(this, 'allTerms', allTerms);
    },

    actions: {
      addTerm() {
        const neu = {
          priority: _component.TERM_PRIORITY.REQUIRED,
          term: {
            _id: (0, _util.randomStr)()
          }
        };
        this.allTerms.push(neu);
        Ember.get(this.nodeAffinity, 'requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms').addObject(neu.term);
        this.notifyPropertyChange('allTerms');
        this.notifyPropertyChange('nodeAffinity');
      },

      removeTerm(term) {
        Ember.get(this, 'allTerms').removeObject(term);

        if (term.priority === _component.TERM_PRIORITY.REQUIRED) {
          const removeFrom = Ember.get(this.nodeAffinity, 'requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms');
          removeFrom.removeObject(term.term);
        } else {
          const removeFrom = Ember.get(this.nodeAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
          removeFrom.removeObject(term.term);
        }

        this.notifyPropertyChange('nodeAffinity');
      },

      typeChanged(term, old) {
        if (old === _component.TERM_PRIORITY.REQUIRED) {
          const removeFrom = Ember.get(this.nodeAffinity, 'requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms');
          const addTo = Ember.get(this.nodeAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
          Ember.set(this.nodeAffinity, 'requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms', this.removeFromSpec(term.term, removeFrom));
          Ember.set(term, 'term', this.addWeight(term.term));
          addTo.pushObject(term.term);
        } else {
          const removeFrom = Ember.get(this.nodeAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
          const addTo = Ember.get(this.nodeAffinity, 'requiredDuringSchedulingIgnoredDuringExecution.nodeSelectorTerms');
          Ember.set(this.nodeAffinity, 'preferredDuringSchedulingIgnoredDuringExecution', this.removeFromSpec(term.term, removeFrom));
          Ember.set(term, 'term', this.removeWeight(term.term));
          addTo.pushObject(term.term);
        }

        this.notifyPropertyChange('nodeAffinity');
      }

    },
    removeFromSpec: (term, array) => {
      return array.filter(each => each._id !== term._id);
    },
    addWeight: term => {
      const out = {
        preference: term,
        _id: term._id
      };
      delete out.preference._id;
      return out;
    },
    removeWeight: term => {
      return {
        _id: term._id,
        ...term.preference
      };
    }
  });

  _exports.default = _default;
});