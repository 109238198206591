define("shared/components/form-pod-affinity-term-k8s/component", ["exports", "shared/components/form-pod-affinity-term-k8s/template", "shared/components/form-pod-affinity-k8s/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.priorityOptions = void 0;
  const namespaceModes = {
    ALL: 'all',
    THIS_POD: 'thisPod',
    IN_LIST: 'inList'
  };
  const priorityOptions = [{
    value: _component.TERM_PRIORITY.REQUIRED,
    label: 'clusterNew.agentConfig.overrideAffinity.podAffinity.typeOptions.required'
  }, {
    value: _component.TERM_PRIORITY.PREFERRED,
    label: 'clusterNew.agentConfig.overrideAffinity.podAffinity.typeOptions.preferred'
  }];
  _exports.priorityOptions = priorityOptions;

  var _default = Ember.Component.extend({
    layout: _template.default,
    namespaceModes,
    priorityOptions,
    TERM_PRIORITY: _component.TERM_PRIORITY,

    /**
     * {
     *  priority: preferred or required
     *  anti: bool - podAffinity or podAntiAffinity
     *  term: podaffinityterm OR
     *   {
     *    weight,
     *    podAffintyTerm
     *   }
     * }
     */
    value: null,
    editing: false,
    remove: null,
    typeChanged: null,
    antiChanged: null,
    affinityOptions: [{
      value: 'true',
      label: 'clusterNew.agentConfig.overrideAffinity.podAffinity.typeOptions.antiAffinity'
    }, {
      value: 'false',
      label: 'clusterNew.agentConfig.overrideAffinity.podAffinity.typeOptions.affinity'
    }],
    actions: {
      removeTerm() {
        if (this.remove) {
          this.remove();
        }
      }

    },
    podAffintyTerm: Ember.computed('value.term', 'value.priority', function () {
      return Ember.get(this.value, 'priority') === _component.TERM_PRIORITY.REQUIRED ? Ember.get(this.value, 'term') : Ember.get(this.value, 'term.podAffinityTerm');
    }),
    weight: Ember.computed('value.term', 'value.priority', {
      get() {
        return Ember.get(this.value, 'priority') === _component.TERM_PRIORITY.REQUIRED ? null : Ember.get(this.value, 'term.weight');
      },

      set(key, val) {
        var _this$value, _this$value$term;

        if (val || val === '0') {
          try {
            const toInt = parseInt(val, 10);
            Ember.set(this.value.term, 'weight', toInt);
          } catch (e) {
            console.error(e);
          }
        } else if ((_this$value = this.value) !== null && _this$value !== void 0 && (_this$value$term = _this$value.term) !== null && _this$value$term !== void 0 && _this$value$term.weight) {
          delete this.value.term.weight;
          this.notifyPropertyChange('value');
        }

        return val;
      }

    }),
    namespaces: Ember.computed('podAffintyTerm.namespaces', 'namespaceMode', {
      get() {
        const namespaces = Ember.get(this.podAffintyTerm, 'namespaces') || [];
        return namespaces.join(', ');
      },

      set(key, val) {
        if (val && val.length) {
          Ember.set(this.podAffintyTerm, 'namespaces', val);
        } else if (this.podAffintyTerm.namespaces) {
          delete this.podAffintyTerm.namespaces;
          this.notifyPropertyChange('value');
        }

        return val;
      }

    }),
    namespaceSelector: Ember.computed('namespaceMode', 'podAffintyTerm.namespaceSelector', {
      get() {
        return this.podAffintyTerm.namespaceSelector || {};
      },

      set(key, val) {
        if (val) {
          Ember.set(this.podAffintyTerm, 'namespaceSelector', val);
        } else if (this.podAffintyTerm.namespaceSelector) {
          delete this.podAffintyTerm.namespaceSelector;
          this.notifyPropertyChange('value');
        }

        return val;
      }

    }),
    labelSelector: Ember.computed('podAffintyTerm.labelSelector.matchExpressions', {
      get() {
        var _this$podAffintyTerm, _this$podAffintyTerm$;

        return ((_this$podAffintyTerm = this.podAffintyTerm) === null || _this$podAffintyTerm === void 0 ? void 0 : (_this$podAffintyTerm$ = _this$podAffintyTerm.labelSelector) === null || _this$podAffintyTerm$ === void 0 ? void 0 : _this$podAffintyTerm$.matchExpressions) || [];
      },

      set(key, val) {
        var _this$podAffintyTerm2, _this$podAffintyTerm3;

        if (val && val.length) {
          if (!this.podAffintyTerm.labelSelector) {
            Ember.set(this.podAffintyTerm, 'labelSelector', {});
          }

          Ember.set(this.podAffintyTerm.labelSelector, 'matchExpressions', val);
        } else if ((_this$podAffintyTerm2 = this.podAffintyTerm) !== null && _this$podAffintyTerm2 !== void 0 && (_this$podAffintyTerm3 = _this$podAffintyTerm2.labelSelector) !== null && _this$podAffintyTerm3 !== void 0 && _this$podAffintyTerm3.matchExpressions) {
          delete this.podAffintyTerm.labelSelector.matchExpressions;
          this.notifyPropertyChange('value');
        }

        return val;
      }

    }),
    priority: Ember.computed('priorityOptions', 'value.priority', {
      get() {
        return Ember.get(this.value, 'priority');
      },

      set(key, val) {
        const old = Ember.get(this.value, 'priority');
        Ember.set(this.value, 'priority', val);
        this.typeChanged(old);
        return val;
      }

    }),
    anti: Ember.computed('affinityOptions', 'value.anti', {
      get() {
        const isAnti = Ember.get(this.value, 'anti');
        return isAnti ? 'true' : 'false';
      },

      set(key, val) {
        const old = Ember.get(this, 'anti') === 'true';
        const neu = val === 'true';
        Ember.set(this.value, 'anti', neu);
        this.antiChanged(old);
        return val;
      }

    }),
    // null or empty namespaces and null selector = 'this pod's namespace'
    // null or empty namespaces and empty object selector = 'all namespaces'
    namespaceMode: Ember.computed('podAffintyTerm.namespaceSelector', 'podAffintyTerm.namespaces.length', {
      get() {
        if (this.podAffintyTerm.namespaceSelector) {
          return namespaceModes.ALL;
        } else if (this.podAffintyTerm.namespaces) {
          return namespaceModes.IN_LIST;
        }

        return namespaceModes.THIS_POD;
      },

      set(key, val) {
        switch (val) {
          case namespaceModes.ALL:
            Ember.set(this, 'namespaceSelector', {});
            Ember.set(this, 'namespaces', null);
            break;

          case namespaceModes.THIS_POD:
            Ember.set(this, 'namespaceSelector', null);
            Ember.set(this, 'namespaces', null);
            break;

          case namespaceModes.IN_LIST:
            Ember.set(this, 'namespaceSelector', null);
        }

        return val;
      }

    })
  });

  _exports.default = _default;
});