define("shared/components/form-engine-opts/component", ["exports", "ui/utils/constants", "shared/components/form-engine-opts/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    layout: _template.default,
    machine: null,
    showEngineUrl: null,
    defaultEngine: Ember.computed.alias(`settings.${_constants.default.SETTING.ENGINE_URL}`),

    didReceiveAttrs() {
      if (!Ember.get(this, 'expandFn')) {
        Ember.set(this, 'expandFn', item => {
          item.toggleProperty('expanded');
        });
      }
    },

    actions: {
      setEngine(url) {
        Ember.set(this, 'machine.engineInstallURL', url);
      }

    },
    engineUrlChoices: Ember.computed(`settings.${_constants.default.SETTING.ENGINE_URL}`, 'defaultEngine', 'intl.locale', function () {
      let defaultEngine = Ember.get(this, 'defaultEngine');
      const defaultEngineVersionObj = {
        label: Ember.get(this, 'intl').t('formEngineOpts.engineInstallUrl.recommendedNoVersion'),
        value: defaultEngine
      }; // Default Engine version - store, so we can check if the option is already in the list below

      let defaultEngineVersion = '';

      try {
        defaultEngineVersion = defaultEngine.split('/').lastObject.replace('.sh', '');

        if (!Ember.isEmpty(defaultEngineVersion)) {
          defaultEngineVersionObj.label = Ember.get(this, 'intl').t('formEngineOpts.engineInstallUrl.recommended', {
            version: defaultEngineVersion
          });
        }
      } catch (_err) {}

      let out = [{
        label: 'v20.10.x',
        value: 'https://releases.rancher.com/install-docker/20.10.sh'
      }, {
        label: 'v19.03.x',
        value: 'https://releases.rancher.com/install-docker/19.03.sh'
      }, {
        label: 'v18.09.x',
        value: 'https://releases.rancher.com/install-docker/18.09.sh'
      }, {
        label: 'v18.06.x',
        value: 'https://releases.rancher.com/install-docker/18.06.sh'
      }, {
        label: 'v17.06.x',
        value: 'https://releases.rancher.com/install-docker/17.06.sh'
      }, {
        label: 'v17.03.x',
        value: 'https://releases.rancher.com/install-docker/17.03.sh'
      }, {
        label: Ember.get(this, 'intl').t('formEngineOpts.engineInstallUrl.latest'),
        value: 'https://get.docker.com'
      }, {
        label: Ember.get(this, 'intl').t('formEngineOpts.engineInstallUrl.none.label'),
        value: 'none'
      }]; // Ensure the engine version is formatted in the same way as the label (currently 'v${VERSION}.x')

      const defaultEngineVersionLabel = Ember.get(this, 'intl').t('formEngineOpts.engineInstallUrl.version', {
        version: defaultEngineVersion
      }); // Remove default engine version if it is already there (in case default is set to one of the options above)

      out = out.filter(opt => opt.label !== defaultEngineVersionLabel); // Add the default at the start of the list

      out.unshift(defaultEngineVersionObj);
      return out;
    })
  });

  _exports.default = _default;
});