define("shared/components/form-affinity-k8s/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ya5e0THv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row mb-10\"],[12],[2,\"\\n    \"],[8,\"form-node-affinity-k8s\",[],[[\"@editing\",\"@nodeAffinity\"],[[34,0],[34,1]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[8,\"form-pod-affinity-k8s\",[],[[\"@editing\",\"@podAffinity\",\"@podAntiAffinity\"],[[34,0],[34,2],[34,3]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"editing\",\"nodeAffinity\",\"podAffinity\",\"podAntiAffinity\"]}",
    "moduleName": "shared/components/form-affinity-k8s/template.hbs"
  });

  _exports.default = _default;
});