define("shared/components/form-node-selector-term-k8s/component", ["exports", "shared/components/form-node-selector-term-k8s/template", "shared/components/form-pod-affinity-term-k8s/component", "shared/components/form-pod-affinity-k8s/component"], function (_exports, _template, _component, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    value: null,
    editing: false,
    priorityOptions: _component.priorityOptions,
    TERM_PRIORITY: _component2.TERM_PRIORITY,
    actions: {
      removeTerm() {
        if (this.remove) {
          this.remove();
        }
      }

    },
    weight: Ember.computed('value.term', 'value.priority', {
      get() {
        return Ember.get(this.value, 'term.weight');
      },

      set(key, val) {
        var _this$value, _this$value$term;

        if (val || val === '0') {
          try {
            const toInt = parseInt(val, 10);
            Ember.set(this.value.term, 'weight', toInt);
          } catch (e) {
            console.error(e);
          }
        } else if ((_this$value = this.value) !== null && _this$value !== void 0 && (_this$value$term = _this$value.term) !== null && _this$value$term !== void 0 && _this$value$term.weight) {
          delete this.value.term.weight;
          this.notifyPropertyChange('value');
        }

        return val;
      }

    }),
    priority: Ember.computed('priorityOptions', 'value.priority', {
      get() {
        return Ember.get(this.value, 'priority');
      },

      set(key, val) {
        const old = Ember.get(this.value, 'priority');
        Ember.set(this.value, 'priority', val);
        this.typeChanged(old);
        return val;
      }

    }),
    nodeSelectorTerm: Ember.computed('value.priority', 'value.term.preference', function () {
      if (this.value.priority === _component2.TERM_PRIORITY.PREFERRED) {
        var _this$value2, _this$value2$term;

        return (_this$value2 = this.value) === null || _this$value2 === void 0 ? void 0 : (_this$value2$term = _this$value2.term) === null || _this$value2$term === void 0 ? void 0 : _this$value2$term.preference;
      }

      return this.value.term;
    }),
    matchExpressions: Ember.computed('nodeSelectorTerm.matchExpressions', {
      get() {
        return Ember.get(this, 'nodeSelectorTerm.matchExpressions') || [];
      },

      set(key, val) {
        if ((!val || !val.length) && this.nodeSelectorTerm.matchExpressions) {
          delete Ember.get(this, 'nodeSelectorTerm').matchExpressions;
          this.notifyPropertyChange('nodeSelectorTerm');
        } else if (val && val.length) {
          Ember.set(this.nodeSelectorTerm, 'matchExpressions', val);
        }

        return val;
      }

    }),
    matchFields: Ember.computed('nodeSelectorTerm.matchFields', {
      get() {
        return Ember.get(this, 'nodeSelectorTerm.matchFields') || [];
      },

      set(key, val) {
        if ((!val || !val.length) && this.nodeSelectorTerm.matchFields) {
          delete Ember.get(this, 'nodeSelectorTerm').matchFields;
          this.notifyPropertyChange('nodeSelectorTerm');
        } else if (val && val.length) {
          Ember.set(this.nodeSelectorTerm, 'matchFields', val);
        }

        return val;
      }

    })
  });

  _exports.default = _default;
});