define("shared/components/form-pod-affinity-k8s/component", ["exports", "shared/components/form-pod-affinity-k8s/template", "shared/utils/util"], function (_exports, _template, _util) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TERM_PRIORITY = void 0;
  const TERM_PRIORITY = {
    PREFERRED: 'preferred',
    REQUIRED: 'required'
  };
  _exports.TERM_PRIORITY = TERM_PRIORITY;

  var _default = Ember.Component.extend({
    layout: _template.default,
    TERM_PRIORITY,
    podAffinity: null,
    podAntiAffinity: null,
    editing: false,
    allTerms: [],

    /**
    * this component renders one list for required & preferred arrays of terms in affinity & antiAffinity
    * each podaffinitytermk8s component can change between affinity and antiaffinity and between required and preferred
    * the overall list shouldn't re-order when a term is moved to a different underlying array so rather than computing this off the arrays in spec
    * this list will track which array a term should belong to and the arrays in spec will be computed off this
    * list of all terms
    * - {
    *    priority: preferred/required
    *    anti: bool
    *    term:preferred or required term
    *   }
    *
    */
    init() {
      var _this$podAffinity, _this$podAffinity2, _this$podAntiAffinity, _this$podAntiAffinity2;

      this._super(...arguments);

      const allTerms = [];

      const addTerms = function (terms = [], priority, isAnti) {
        terms.forEach(term => {
          allTerms.push({
            priority,
            anti: isAnti,
            term
          });
        });
      };

      addTerms((_this$podAffinity = this.podAffinity) === null || _this$podAffinity === void 0 ? void 0 : _this$podAffinity.preferredDuringSchedulingIgnoredDuringExecution, TERM_PRIORITY.PREFERRED, false);
      addTerms((_this$podAffinity2 = this.podAffinity) === null || _this$podAffinity2 === void 0 ? void 0 : _this$podAffinity2.requiredDuringSchedulingIgnoredDuringExecution, TERM_PRIORITY.REQUIRED, false);
      addTerms((_this$podAntiAffinity = this.podAntiAffinity) === null || _this$podAntiAffinity === void 0 ? void 0 : _this$podAntiAffinity.preferredDuringSchedulingIgnoredDuringExecution, TERM_PRIORITY.PREFERRED, true);
      addTerms((_this$podAntiAffinity2 = this.podAntiAffinity) === null || _this$podAntiAffinity2 === void 0 ? void 0 : _this$podAntiAffinity2.requiredDuringSchedulingIgnoredDuringExecution, TERM_PRIORITY.REQUIRED, true);
      Ember.set(this, 'allTerms', allTerms);
    },

    actions: {
      addTerm() {
        const neu = {
          priority: TERM_PRIORITY.REQUIRED,
          anti: false,
          term: {
            _id: (0, _util.randomStr)()
          }
        };
        this.allTerms.push(neu);
        Ember.get(this.podAffinity, 'requiredDuringSchedulingIgnoredDuringExecution').addObject(neu.term);
        this.notifyPropertyChange('allTerms');
        this.notifyPropertyChange('podAffinity');
      },

      removeTerm(term) {
        Ember.get(this, 'allTerms').removeObject(term);

        if (term.anti) {
          if (term.priority === TERM_PRIORITY.REQUIRED) {
            const removeFrom = Ember.get(this.podAntiAffinity, 'requiredDuringSchedulingIgnoredDuringExecution');
            removeFrom.removeObject(term.term);
          } else {
            const removeFrom = Ember.get(this.podAntiAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
            removeFrom.removeObject(term.term);
          }

          this.notifyPropertyChange('podAntiAffinity');
        } else {
          if (term.priority === TERM_PRIORITY.REQUIRED) {
            const removeFrom = Ember.get(this.podAffinity, 'requiredDuringSchedulingIgnoredDuringExecution');
            removeFrom.removeObject(term.term);
          } else {
            const removeFrom = Ember.get(this.podAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
            removeFrom.removeObject(term.term);
          }

          this.notifyPropertyChange('podAffinity');
        }
      },

      typeChanged(term, old) {
        if (term.anti) {
          if (old === TERM_PRIORITY.REQUIRED) {
            const removeFrom = Ember.get(this.podAntiAffinity, 'requiredDuringSchedulingIgnoredDuringExecution');
            const addTo = Ember.get(this.podAntiAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
            Ember.set(this.podAntiAffinity, 'requiredDuringSchedulingIgnoredDuringExecution', this.removeFromSpec(term.term, removeFrom)); // preferred terms are an object with weight and podAffinityTerm fields; required terms are just the contents of podAffinityTerm

            Ember.set(term, 'term', this.addWeight(term.term));
            addTo.pushObject(term.term);
          } else {
            const removeFrom = Ember.get(this.podAntiAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
            const addTo = Ember.get(this.podAntiAffinity, 'requiredDuringSchedulingIgnoredDuringExecution');
            Ember.set(this.podAntiAffinity, 'preferredDuringSchedulingIgnoredDuringExecution', this.removeFromSpec(term.term, removeFrom));
            Ember.set(term, 'term', this.removeWeight(term));
            addTo.pushObject(term.term);
          }

          this.notifyPropertyChange('podAntiAffinity');
        } else {
          if (old === TERM_PRIORITY.REQUIRED) {
            const removeFrom = Ember.get(this.podAffinity, 'requiredDuringSchedulingIgnoredDuringExecution');
            const addTo = Ember.get(this.podAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
            Ember.set(this.podAffinity, 'requiredDuringSchedulingIgnoredDuringExecution', this.removeFromSpec(term.term, removeFrom));
            Ember.set(term, 'term', this.addWeight(term.term));
            addTo.pushObject(term.term);
          } else {
            const removeFrom = Ember.get(this.podAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
            const addTo = Ember.get(this.podAffinity, 'requiredDuringSchedulingIgnoredDuringExecution');
            Ember.set(this.podAffinity, 'preferredDuringSchedulingIgnoredDuringExecution', this.removeFromSpec(term.term, removeFrom));
            Ember.set(term, 'term', this.removeWeight(term));
            addTo.pushObject(term.term);
          }

          this.notifyPropertyChange('podAffinity');
        }
      },

      antiChanged(term, old) {
        if (old) {
          if (term.priority === TERM_PRIORITY.REQUIRED) {
            const removeFrom = Ember.get(this.podAntiAffinity, 'requiredDuringSchedulingIgnoredDuringExecution');
            const addTo = Ember.get(this.podAffinity, 'requiredDuringSchedulingIgnoredDuringExecution');
            Ember.set(this.podAntiAffinity, 'requiredDuringSchedulingIgnoredDuringExecution', this.removeFromSpec(term.term, removeFrom));
            addTo.pushObject(term.term);
          } else {
            const removeFrom = Ember.get(this.podAntiAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
            const addTo = Ember.get(this.podAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
            Ember.set(this.podAntiAffinity, 'preferredDuringSchedulingIgnoredDuringExecution', this.removeFromSpec(term.term, removeFrom));
            addTo.pushObject(term.term);
          }
        } else {
          if (term.priority === TERM_PRIORITY.REQUIRED) {
            const removeFrom = Ember.get(this.podAffinity, 'requiredDuringSchedulingIgnoredDuringExecution');
            const addTo = Ember.get(this.podAntiAffinity, 'requiredDuringSchedulingIgnoredDuringExecution');
            Ember.set(this.podAffinity, 'requiredDuringSchedulingIgnoredDuringExecution', this.removeFromSpec(term.term, removeFrom));
            addTo.pushObject(term.term);
          } else {
            const removeFrom = Ember.get(this.podAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
            const addTo = Ember.get(this.podAntiAffinity, 'preferredDuringSchedulingIgnoredDuringExecution');
            Ember.set(this.podAffinity, 'preferredDuringSchedulingIgnoredDuringExecution', this.removeFromSpec(term.term, removeFrom));
            addTo.pushObject(term.term);
          }
        }

        this.notifyPropertyChange('podAffinity');
        this.notifyPropertyChange('podAntiAffinity');
      }

    },
    removeFromSpec: (term, array) => {
      return array.filter(each => each._id !== term._id);
    },
    addWeight: term => {
      const out = {
        // weight:          null,
        podAffinityTerm: term,
        _id: term._id
      };
      delete out.podAffinityTerm._id;
      return out;
    },
    removeWeight: term => {
      return {
        _id: term._id,
        ...term.podAffinityTerm
      };
    }
  });

  _exports.default = _default;
});